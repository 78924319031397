import React, { Component } from 'react'

import ProductList from './components/ProductList'
import './App.css'

export default class App extends Component {
  render() {
    return (
      <div className="stock-container">
        <header>
          <h1>Lista de precios</h1>
        </header>
        <ProductList />
      </div>
    )
  }
}