import React, { Component } from 'react'

import './ProductModal.css'

export default class ProductModal extends Component {
  dismiss() {
    this.props.hideModal()
    this.props.clearModal()
  }

  escFunction = event => {
    if(event.keyCode === 27) {
      this.dismiss()
    }
  }

  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    if (this.props.visible) {
      return <div>
        <div className="modal-dark" onClick={() => this.dismiss()} />
        <div className="modal-content">
          <button className="close-modal" onClick={() => this.dismiss()}><i className="fas fa-times" /></button>
          <form onSubmit={event => this.props.handleSubmit(event)}>
            <input
              type="hidden"
              value={this.props.values.id}
              name="id"
            />
            <div className="input-group">
              <label>Nombre</label>
              <input
                type="text"
                value={this.props.values.name}
                name="name"
                onChange={event => this.props.handleChange(event)}
                required
                autoFocus
              />
            </div>
            <div className="input-group">
              <label>Precio</label>
              <input
                type="number"
                value={this.props.values.price}
                min="0"
                step=".0001"
                name="price"
                onChange={event => this.props.handleChange(event)}
                required
              />
            </div>
            <div className="input-group">
              <input type="submit" value="Guardar" />
            </div>
          </form>
        </div>
      </div>
    } else {
      return null
    }
  }
}