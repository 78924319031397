import React, { Component } from 'react'
import './Product.css'

export default class Product extends Component {
  confirmDelete() {
    if (window.confirm('¿Está seguro que quiere eliminar este producto?')) {
      this.props.delete(this.props.product._id)
      this.props.hideModal()
      this.props.clearModal()
    }
  }

  formatNumber(number) {
    return parseFloat(number).toLocaleString('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  render() {
    const rawPrice = this.formatNumber(this.props.product.price)
    const netPrice = this.formatNumber(this.props.product.price * 1.21)

    return <li>
      <div title="Nombre">{this.props.product.name}</div>
      <div className="price" title="Precio sin IVA">{rawPrice}</div>
      <div className="price" title="Precio con IVA">{netPrice}</div>
      <div className="no-print actions">
        <button
          className="edit-button"
          onClick={event => this.props.editModal({
            id: this.props.product._id,
            name: this.props.product.name,
            price: this.props.product.price
          }, event)}
          title="Editar"
        >
          <i className="fas fa-edit" />
        </button>
        <button
          className="delete-button"
          onClick={() => this.confirmDelete()}
          title="Eliminar"
        >
          <i className="fas fa-trash-alt" />
        </button>
      </div>
    </li>
  }
}