import React, { Component } from 'react'

import axios from '../lib/axios'
import Product from './Product'
import ProductModal from './ProductModal'
import './ProductList.css'

export default class ProductList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      groupList: [],
      modalVisible: false,
      modalValues: {
        id: '',
        name: '',
        price: ''
      }
    }
  }

  async componentDidMount() {
    await this.getProducts()
  }

  showModal = values => {
    if (values !== this.state.modalValues) {
      this.setState({modalValues: values})
    }

    this.setState({modalVisible: true})
  }

  hideModal = () => {
    this.setState({modalVisible: false})
  }

  clearModal = () => {
    this.setState({id: '', name: '', price: ''})
  }

  handleChange = event => {
    event.preventDefault()

    const target = event.target
    const name = target.name
    const value = target.value

    this.setState({modalValues: {...this.state.modalValues, [name]: value}})
  }

  handleSubmit = async event => {
    event.preventDefault()

    this.hideModal()
    if (this.state.modalValues.id !== '') {
      await this.updateProduct(this.state.modalValues, event)
    } else {
      await this.addProduct(this.state.modalValues, event)
    }
    
    this.clearModal()
  }

  getProducts = async () => {
    try {
      const response = await axios.get('/products')
      await this.setState({groupList: this.groupByInitial(response.data)})
    } catch(error) {
      console.error(error)
    }
  }

  groupByInitial(products) {
    const data = products.reduce((accumulator, currentValue) => {
      let initial = currentValue.name[0].toUpperCase()
      
      if (!accumulator[initial]) {
        accumulator[initial] = {initial, products: [currentValue]}
      } else {
        accumulator[initial].products.push(currentValue)
      }

      return accumulator
    }, {})

    return Object.values(data)
  }

  addProduct = async (values, event) => {
    event.preventDefault()
    try {
      await axios.post(
        '/product',
        {
          name: values.name,
          price: values.price
      })
      this.getProducts()
    } catch(error) {
      console.error(error)
    }
  }

  updateProduct = async (values, event) => {
    event.preventDefault()
    try {
      await axios.put(
        '/product',
        {
          product: values.id,
          values: {
            name: values.name,
            price: values.price
        }
      })
      this.getProducts()
    } catch(error) {
      console.error(error)
    }
  }

  deleteProduct = async id => {
    try {
      await axios.delete('/product', {
        data: {
          product: id
        }
      })
      this.getProducts()
    } catch(error) {
      console.error(error)
    }
  }

  renderProducts = products => {
    const productList = products.map(product => <Product
      key={product._id}
      product={product}
      editModal={this.showModal}
      hideModal={this.hideModal}
      clearModal={this.clearModal}
      delete={this.deleteProduct}
    />)

    return productList
  }

  render() {
    const groupList = this.state.groupList.map(group => {
      return <div key={group.initial}>
        <h2>{group.initial}</h2>
        <ul>{this.renderProducts(group.products)}</ul>
      </div>
    })

    return <div>
      <button
        id="add-product"
        className="no-print"
        title="Añadir producto"
        onClick={() => this.showModal({
          id: '',
          name: '',
          price: ''
        })}
      >
        <i className="fas fa-plus" />
      </button>
      <ul>{groupList}</ul>
      <ProductModal
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        values={this.state.modalValues}
        visible={this.state.modalVisible}
        hideModal={this.hideModal}
        clearModal={this.clearModal}
      />
    </div>
  }
}